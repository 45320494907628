import { useCallback, useEffect, useState } from "react";
import { paramsToArray } from "../assets/js/utils/functions";
import { client } from "./client";

const NODE_ENV = process.env.NODE_ENV;
const MSG_RETRY_NETWORK = "Please check your network and try again.";
const MSG_SERVER_ERR = "Something went wrong.";
const DEV_ERRORS = ["SyntaxError", "TypeError"];

class RequestError extends Error {
  public exception: any;
  public response: any;

  constructor(ex: any, ...args: any) {
    super(...args);

    this.exception = ex;
    this.response = ex.response;

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, RequestError);
    }
  }
}

function getErrorMessage(e: any): string {
  const { message, name } = e;
  if (message) {
    if (e instanceof TypeError && message === "Failed to fetch") {
      return MSG_RETRY_NETWORK;
    }

    const hideMessage = DEV_ERRORS.includes(name);
    return hideMessage ? MSG_SERVER_ERR : message;
  } else {
    return MSG_RETRY_NETWORK;
  }
}

function errorHandler(err: any) {
  const msg = getErrorMessage(err);
  return new RequestError(err, msg);
}

class CustomError extends Error {
  message: string;
  body: any;

  constructor(error: any) {
    super();
    this.message = error.message;
    this.body = { ...error };
  }
}

// function CustomError(error) {
// 	this.body = {...error};
// 	this.message = error.message;
// }

// CustomError.prototype = Error.prototype;

export async function request(endpoint: string, method: string, conf = {}, baseUrl?: string) {
  const config = { ...conf };

  try {
    const response = await client(endpoint, method, { ...config }, baseUrl);
    const data = response;

    const err = response.status > 299 ? new CustomError(data) : null;

    return [data, err];
  } catch (err) {
    if (NODE_ENV !== "production") {
      console.error(`request failed on endpoint ${endpoint}`);
    }
    return [null, err];
  }
}

interface CustomErrorType extends Error {
  statusCode?: number;
}

interface useRequestState {
  isLoading: boolean;
  error: null | CustomErrorType;
}

export interface RequestInterface {
  response: any;
  makeRequest: (data: any, ...params: any) => Promise<any[]>;
  clearResponse: () => void;
  isLoading: boolean;
  error: CustomErrorType;
}

export function useRequest<P = any>(func: (...args: any) => any, def?: any) {
  const [state, setState] = useState<useRequestState>({ isLoading: false, error: null });
  const [response, setResponse] = useState(def);

  const makeRequest = async (data: P, ...params: any) => {
    setResponse(def);

    setState({ isLoading: true, error: null });

    const [response, error] = await func({ ...params, ...data });
    setResponse(response);
    setState({ isLoading: false, error });
    return [response, error];
  };

  const clearResponse = () => {
    setResponse(def);
    setState({ ...state, error: null });
  };

  return {
    ...state,
    response,
    makeRequest,
    clearResponse,
  };
}

export function useFetcher<P = any>(func: (...args: any) => any, params?: P, required?: any[]) {
  const [state, setState] = useState<useRequestState>({ isLoading: false, error: null });
  const [response, setResponse] = useState<any>(null);
  const deps = params ? [...paramsToArray(params)] : [];

  useEffect(() => {
    const newParams: any = { ...params };
    const available = required?.map((param) => newParams[param] !== null && newParams !== undefined);

    if (available?.includes(false)) return;

    if (state.isLoading) return;

    makeRequest();
  }, deps);

  const makeRequest = async () => {
    setState({ isLoading: true, error: null });
    const [response, error] = await func({ ...params });
    setResponse(response);
    setState({ isLoading: false, error });
    return [response, error];
  };

  return {
    ...state,
    response,
    makeRequest,
  };
}
